<template>
  <div class="container mt-5">
    <div class="row bortr mt-2">
      <div class="col-sm-12">
        <h1 class="text-center"> Exit Stock Document </h1>
      </div>
    </div>
    <div class="row bortr">
      <div class="col-sm-6 p-0">
        <table class="table table-sm m-0">
          <tbody>
            <tr class="no-border">
              <td>From Location</td>
              <td>{{ master.from_project_tile }}</td>
            </tr>
            <tr class="no-border">
              <td>To Location</td>
              <td>{{ master.to_project_tile }}</td>
            </tr>
            <tr class="no-border">
              <td>Exit Date</td>
              <td>{{ master.exit_date }}</td>
            </tr>
            <tr class="no-border">
              <td> Po #</td>
              <td>{{ master.po_num }}</td>
            </tr>
          </tbody>
        </table>
        <br>
        <br>
        <br>
      </div>
    </div>
    <div class="row">
      <table class="table table-sm m-0">
        <thead>
          <tr>
            <td class="text-center"> SKU </td>
            <td class="text-center"> Title </td>
            <td class="text-center"> Qty </td>
          </tr>
        </thead>
        <tbody>
          <!-- <tr v-for="(value, index) in items" :key="index">
            <td class="text-center"> {{ value.sku }} </td>
            <td class="text-center"> {{ value.title }} </td>
            <td class="text-center"> {{ value.qty }} </td>
          </tr> -->
          <tr v-for="(value, index) in events" :key="index">
            <td class="text-center"> {{ value.sku }} </td>
            <td class="text-center"> {{ value.title }} </td>
            <td class="text-center"> {{ value.qty }} </td>
          </tr>
        </tbody>
      </table>
    </div>
    <br>
    <br>
    <br>
    <div class="row">

      <table class="table table-sm no-border">
        <tbody>
          <tr>
            <td class="text-center">Project In Charge</td>
            <td class="text-center">Issued By</td>
            <td class="text-center">Received By</td>
          </tr>
          <tr>
            <td class="text-center">{{ master.project_in_charge }}</td>
            <td class="text-center">{{ master.issue_by }}</td>
            <td class="text-center">{{ master.received_by }}</td>
          </tr>
        </tbody>
      </table>
    </div>
  </div>
</template>
<script>

import productservice from "../../../services/product-service"
const VueQrcode = require("@chenfengyuan/vue-qrcode");

const converter = require("number-to-words");

export default {
  name: "SalesInvoice",
  components: {
    VueQrcode: VueQrcode,
  },
  data: function () {
    return {
      master: {},
      items: [],
      events: []
    };
  },
  mounted() {
    this.retrieve(this.$route.params.id);
  },
  methods: {
    trigger(t) {
      alert(t);
    },
    toggle() {
      this.show = !this.show;
    },
    comma(data) {
      //  return data
      return Number(parseFloat(data).toFixed(2)).toLocaleString("en", {
        minimumFractionDigits: 2,
      });
    },
    sum(n1) {
      let vat = Number(n1) * 0.15;
      let total = Number(n1) + vat;
      return this.comma(total);
    },
    retrieve(id) {
      productservice.get_item_reqeust_out(id)
        .then((response) => {
          // this.items = response.data["items"];
          this.events = response.data["events"];
          this.master = response.data["master"][0];
        })
        .catch((e) => {
          console.log(e);
        });
    },
  },
};
</script>
<style scoped>
.word {
  text-transform: capitalize;
}

.fa-bold {
  font-weight: bold;
}

.float-center {
  text-align: center;
}

.table tr td {
  font-size: 14px !important;
  font-weight: bold;
  margin: 0;
  padding: 0px !important;
  padding-left: 4px !important;
  padding-right: 4px !important;
}

.bortr {
  border: black solid 1px;
}

.no-border {
  border-left: black solid 1px;
  border-right: black solid 1px;
}

.right-border {
  border-right: black solid 1px !important;
}

.top-boder {
  border-top: solid 1px black !important;
}

.bottom-boder {
  border-bottom: solid 1px black !important;
}

.table td {
  border: solid 1px black;
}

.td-3 {
  border-right: solid 1px black !important;
}

.row-tr td {
  border: black solid 1px;
}

.fa-nb {
  font-weight: 300 !important;
}

.f-14 {
  font-size: 14px !important;
}

.image {
  width: 100px;
  align-self: center;
}

.header-arabic {
  align-self: flex-end;
  font-size: 15px;
  width: 300px;
  border: 1px solid #000;
}

.al-right {
  align-self: flex-end;
}

.df {
  display: flex;
  align-items: center;
}

.center {
  display: flex;
  align-items: center;
  justify-content: center;
}

.text-sm {
  font-size: 10px;
}

.text-md {
  font-size: 14px;
}</style>